import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  return (
    <Layout>
      <SEO title="Home" />

      {/* https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE4pCWy */}
      <h1>Azure AZ-104 profile</h1>

      <p>
        The Azure Administrator implements, manages, and monitors identity,
        governance, storage, compute, and virtual networks in a cloud
        environment. The Azure Administrator will provision, size, monitor, and
        adjust resources as appropriate.
      </p>

      <p>
        <Link to={"/".concat("questions")} className="inline">
          Click here to access {data.allMarkdownRemark.totalCount} questions
        </Link>
      </p>

      <h2>Manage Azure identities and governance</h2>

      <h3>Manage Azure AD objects</h3>
      <ul>
        <li>create users and groups</li>
        <li>manage user and group properties</li>
        <li>manage device settings</li>
        <li>perform bulk user updates</li>
        <li>manage guest accounts</li>
        <li>configure Azure AD Join</li>
        <li>configure self-service password reset</li>
        <li>NOT: Azure AD Connect; PIM</li>
      </ul>

      <h3>Manage role-based access control (RBAC)</h3>
      <ul>
        <li>create a custom role</li>
        <li>
          provide access to Azure resources by assigning roles: subscriptions,
          resource groups, resources (VM, disk, etc.)
        </li>
        <li>interpret access assignments</li>
        <li>manage multiple directories</li>
      </ul>

      <h3>Manage subscriptions and governance</h3>
      <ul>
        <li>configure Azure policies</li>
        <li>configure resource locks</li>
        <li>apply tags</li>
        <li>create and manage resource groups: move resources, remove RGs</li>
        <li>manage subscriptions</li>
        <li>configure Cost Management</li>
        <li>configure management groups</li>
      </ul>

      <h2>Implement and manage storage</h2>

      <h3>Manage storage accounts</h3>
      <ul>
        <li>configure network access to storage accounts</li>
        <li>create and configure storage accounts</li>
        <li>generate shared access signature</li>
        <li>manage access keys</li>
        <li>implement Azure storage replication</li>
        <li>configure Azure AD Authentication for a storage account</li>
      </ul>

      <h3>Manage data in Azure Storage</h3>
      <ul>
        <li>export from Azure job</li>
        <li>import into Azure job</li>
        <li>install and use Azure Storage Explorer</li>
        <li>copy data by using AZCopy</li>
      </ul>

      <h3>Configure Azure files and Azure blob storage</h3>
      <ul>
        <li>create an Azure file share</li>
        <li>create and configure Azure File Sync service</li>
        <li>configure Azure blob storage</li>
        <li>configure storage tiers for Azure blobs</li>
      </ul>

      <h2>Deploy and manage Azure compute resources</h2>

      <h3>Configure VMs for high availability and scalability</h3>
      <ul>
        <li>configure high availability</li>
        <li>deploy and configure scale sets</li>
      </ul>

      <h3>Automate deployment and configuration of VMs</h3>
      <ul>
        <li>modify Azure Resource Manager (ARM) template</li>
        <li>configure VHD template</li>
        <li>deploy from template</li>
        <li>save a deployment as an ARM template</li>
        <li>
          automate configuration management by using custom script extensions
        </li>
      </ul>

      <h3>Create and configure VMs</h3>
      <ul>
        <li>configure Azure Disk Encryption</li>
        <li>move VMs from one resource group to another</li>
        <li>manage VM sizes</li>
        <li>add data discs</li>
        <li>configure networking</li>
        <li>redeploy VMs</li>
      </ul>

      <h3>Create and configure containers</h3>
      <ul>
        <li>create and configure Azure Kubernetes Service (AKS)</li>
        <li>create and configure Azure Container Instances (ACI)</li>
        <li>
          NOT: selecting an container solution architecture or product;
          container registry settings
        </li>
      </ul>

      <h3>Create and configure Web Apps</h3>
      <ul>
        <li>create and configure App Service</li>
        <li>create and configure App Service Plans</li>
        <li>NOT: Azure Functions; Logic Apps; Event Grid</li>
      </ul>

      <h2>Configure and manage virtual networking</h2>
      <h3>Implement and manage virtual networking</h3>
      <ul>
        <li>create and configure VNET peering</li>
        <li>
          configure private and public IP addresses, network routes, network
          interface, subnets, and virtual network
        </li>
      </ul>

      <h3>Configure name resolution</h3>
      <ul>
        <li>configure Azure DNS</li>
        <li>configure custom DNS settings</li>
        <li>configure a private or public DNS zone</li>
      </ul>

      <h3>Secure access to virtual networks</h3>
      <ul>
        <li>create security rules</li>
        <li>associate an NSG to a subnet or network interface</li>
        <li>evaluate effective security rules</li>
        <li>deploy and configure Azure Firewall</li>
        <li>deploy and configure Azure Bastion Service</li>
        <li>NOT: Implement Application Security Groups; DDoS</li>
      </ul>

      <h3>Configure load balancing</h3>
      <ul>
        <li>configure Application Gateway</li>
        <li>configure an internal load balancer</li>
        <li>configure load balancing rules</li>
        <li>configure a public load balancer</li>
        <li>troubleshoot load balancing</li>
        <li>NOT: Traffic Manager and FrontDoor and PrivateLink</li>
      </ul>

      <h3>Monitor and troubleshoot virtual networking</h3>
      <ul>
        <li>monitor on-premises connectivity</li>
        <li>use Network Performance Monitor</li>
        <li>use Network Watcher</li>
        <li>troubleshoot external networking</li>
        <li>troubleshoot virtual network connectivity</li>
      </ul>

      <h3>Integrate an on-premises network with an Azure virtual network</h3>
      <ul>
        <li>create and configure Azure VPN Gateway</li>
        <li>create and configure VPNs</li>
        <li>configure ExpressRoute</li>
        <li>configure Azure Virtual WAN</li>
      </ul>

      <h2>Monitor and back up Azure resources</h2>
      <h3>Monitor resources by using Azure Monitor</h3>
      <ul>
        <li>
          configure and interpret metrics: analyze metrics across subscriptions
        </li>
        <li>
          configure Log Analytics: implement a Log Analytics workspace,
          configure diagnostic settings
        </li>
        <li>
          query and analyze logs: create a query, save a query to the dashboard,
          interpret graphs
        </li>
        <li>
          set up alerts and actions: create and test alerts, create action
          groups, view alerts in Azure Monitor, analyze alerts across
          subscriptions
        </li>
        <li>configure Application Insights</li>
        <li>NOT: Network monitoring</li>
      </ul>

      <h3>Implement backup and recovery</h3>
      <ul>
        <li>configure and review backup reports</li>
        <li>perform backup and restore operations by using Azure Backup</li>
        <li>
          create a Recovery Services Vault: use soft delete to recover Azure VMs
        </li>
        <li>create and configure backup policy</li>
        <li>perform site-to-site recovery by using Azure Site Recovery</li>
        <li>NOT: SQL or HANA</li>
      </ul>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQueryIndex {
    allMarkdownRemark(filter: { frontmatter: { number: { gte: 0 } } }) {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            number
            title
          }
        }
      }
      totalCount
    }
  }
`
